@import "colors";

.floating-button{
    position: fixed;
    top: 70%;
    right: 0;
    z-index: 99;
    a{
        padding: 25px 15px;
        background: $theme-blue-secondary;
        color: white;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.319);
        text-orientation: sideways;
        writing-mode: vertical-lr;
        border-radius: 0 5px 5px 0;
        transform: translateY(-50%) rotate(180deg);
        font-weight: bold;
        // text-transform: uppercase;
        transition: all 300ms;
        &:hover{
            background: $theme-orange;
            transition: all 300ms;
        }
    }
}