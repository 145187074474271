@import "colors";

section.privacy{
    // background: $theme-blue-light;
    background: white;
    article{
        padding-top: 1rem;
        h1,
        p>strong{
            color: $theme-blue; 
            font-size: 2rem;
        }
        p,li, td{
            color: $theme-dark !important;
            line-height: 1.75;
        }
        p>strong{
            font-size: 1.5rem;
            display: block;
            padding-top: 1rem;
        }
        table, tr, td{
            border: 1px solid #d6d6d6;
            border-collapse: collapse;
            padding: 20px;
            font-size: 14px;
            color: black !important;
            em{
                color: grey !important;
            }
        }
        table{
            width: 100%;
            margin: 20px 0;
        }
        .content-list-bullets{
            list-style-type: circle;
            li{
                padding: 5px 0;
            }
        }
        .updated{
            color: $theme-blue !important;
            margin-top: 2rem;
        }
    }
}