@import "colors";


.solutions-cards-container{
    .tile{
        position: absolute !important;

    }

    .tile1{
        top: -5rem !important;
        left: 44rem !important;
    }

    .tile2{
        top: 50rem !important;
        left: 13rem !important;
    }

    .tile3{
        top: 105rem !important;
        left: 90rem !important;
    }

    .content-wrapper{
        &:last-child .products-wrapper{
            margin-bottom: 100px;
        }
        .products-wrapper{
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 250px;
            .products-item{
                img{
                    width: 50%;
                    margin: auto;
                    margin-top: -120px;
                    margin-bottom: 50px;
                }

                h1{
                    margin-bottom: 50px;
                    color: #ff9114;
                    font-weight: 600;
                    text-align: center;            
                }
                p{
                    margin-bottom: 20px;
                }
                cursor: default;
                display: flex;
                flex-direction: column;
                width: 500px;
                margin: auto;
                padding-left: 30px;
            }
        }
    }
}

.solutions-cards-container,
.products,
.solutions{
    p{
        font-size: 20px !important;
    }
    .intro{
        margin-bottom: 100px;
    }
}

@media screen and (max-width: 900px) {
    .solutions-cards-container.products{
        position: relative;
        padding-top: 10rem !important;
        .tile1{
            left: 10px !important;
            top: 10px !important;
        }
        .tile2{
            left: 10px !important;
            top: 50% !important;
        }
        .tile3{
            top: unset !important;
            left: unset !important;
            right: 10px !important;
            bottom: 10px !important;
        }
        .content-wrapper{
            &:last-child{
                margin-top: -200px !important;
            }
            .products-wrapper{
                grid-template-columns: 1fr !important;
                .products-item{
                    margin-top: 5rem !important;
                    p{
                        font-size: 20px !important;
                    }
                }
            }
        }
    }
}