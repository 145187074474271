@media screen and (max-width: 1550px) {
    /* header{
        // padding: 2.5rem 5% 0 150px !important;
        nav a{
            padding: 1rem .75rem !important;
        }
    } */
}
@media screen and (max-width: 1350px) {
    /* header{
        // padding: 2.5rem 2% 0 90px !important;
        &.fixed{padding-bottom: 20px !important;}
        nav li{
            margin: 0 !important;
            &:first-child{margin-left: 10px !important;}
            &.quote{margin-left: 10px !important;}
            &.nested ul li{
                margin: 0 !important;
                a{padding: 1rem 1.1rem !important;}
            }
            a{font-size: 14px !important;}
        } 
        .logo{
            img{height: 60px !important;}
        }
    } */
    footer{
        .logo{
            padding-left: 5rem !important;
            padding-bottom: 5rem !important;
        }
        .footer-inner-wrapper{
            display: grid !important;
            grid-template-columns: 50% 50%;
            width: 100%;
            &>div{
                &:first-child{
                    grid-column: span 2;
                }
            }
        }
    }
    .banner{
        .title{font-size: 1.9rem !important;}
        h3{font-size: 1rem !important;}
        .cta{
            text-align: left;
            a{
                margin: 0 !important;
                margin-top: 10px !important;
                text-align: center;
            }
        }
        .left{
            .tile img:not(.inner-banner-tile){
                width: 75px !important;
            }
        }
    }
    .contact-us{
        img{
            max-width: 300px !important;
            height: auto !important;
        }
    }
    .pricing{
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        .content-wrapper{
            display: grid !important;
            grid-template-columns: repeat(3, 1fr) !important;   
            grid-gap: 10px;
            &>div:first-child{
                grid-column: span 3;
            }
            .card-prices{
                width: 100%;
            }
            .card-prices-item-wrapper{
                padding-left: 10% !important;
                padding-right: 10% !important;
                margin: 0 !important;
                max-width: 100% !important;
            }
        }
    }
    .products{
        padding-left: 5rem !important;
        padding-right: 5rem !important;
        .products-wrapper .title{padding: 0 !important;}
    }
    .prod-serv-mid-banner{
        padding-left: 5% !important;
        padding-right: 5% !important;
    }
}

@media screen and (min-width: 1026px) and (max-width: 1350px) {
    .products{
        .title{
            padding: 8rem 2rem 0 !important;
            text-align: center;
        }
        .products-wrapper{
            // .icon-wrapper img{height: 55px !important;}
            .products-item div.title{
                font-size: 1.5rem !important;
                text-align: left;
            }
        }
    }
}

@media screen and (max-width: 1024px) {
    
        #clickToCallCTA{
            display: block !important;
        }
    header .menu nav ul > li:first-child{
        margin-left: 0 !important;
    }
    .referralAndPartnerBanner{
        .content-wrapper{
            grid-template-columns: 1fr !important;
        }
    }
    .latestnews{
        .news-tiles{
            grid-template-columns: 1fr 1fr !important;
            .highlight{
                display: block;
                grid-column: 1/span 2;
                grid-row: unset !important;
                padding-bottom: 30px !important;
                .news-image{
                    padding-top: 30% !important;
                    img{
                        object-position: top !important;
                    }
                }
            }
            .news-item{
                margin-bottom: 20px !important;
            }
        }
    }
    .contact-page{
        padding-top: 15.5rem !important;
        .tile:not(.tile-second){
            top: -123px !important;
            left: 324px !important;
        }
    }
    .success{
        .content-wrapper{
            max-width: 600px !important;
            margin: auto;
        }
    }
    .products{
        padding-top: 13rem !important;
        .tile{
            top: 4rem !important;
        }
        .content-wrapper{
            .title{
                padding-left: 2rem !important;
                margin-bottom: 2rem !important;
            }
            .products-wrapper{
                // grid-gap: 50px 0px;
                grid-template-columns: 1fr !important;
                .title{
                    padding: 0 !important;
                }
                .products-item{
                    max-width: 500px;
                    margin: 0 auto 30px;
                    .card-trigger{
                        .subtitle{
                            font-size: 20px !important;
                            height: 90px;
                        }
                        .title{
                            font-size: 24px !important;
                            height: 200px;
                        }
                    }
                    .Collapsible__contentInner p{
                        font-size: 20px !important;
                        padding: 20px 0;
                    }
                }
            }
        }
    }
    .banner{
        .content-wrapper{
            display: flex !important;
            flex-direction: column-reverse;
            justify-content: flex-end;
            .logo{
                position: absolute;
                top: 0;
                z-index: 1;
                filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.5));
            }
            .imgbanner-wrapper, 
            .imgbanner-wrapper [class*=css-] {
                height: 50vh;
                max-height: 500px;
                img{
                    object-position: center;
                }
            }
            .brand{
                padding-top: 2rem !important;
                padding-bottom: 2rem !important;
                display: flex !important;
                flex-direction: column;
                div:first-child{
                    align-self: unset !important;
                }
                .title br:not(:nth-child(2)){
                    display: none;
                }
                br:first-child{
                    display: block !important;
                }
            }
        //     grid-template-columns: 50% 1fr !important;
        //     .left{
        //         padding-left: 50px !important;
        //     }
        }
        // .left .home-banner-tile img{
        //     position: absolute;
        //     opacity: .15;
        //     width: 400px !important;
        //     top: -200px;
        // }
    }
    section{
        padding: 7.5rem 50px !important;
    }
    header{
        padding-left: 50px !important;
        padding-top: 30px !important;
        .quote a{
            padding: 1rem 2rem !important;
            font-size: 25px !important;
            align-self: flex-start;
            margin-bottom: 30px;
            svg{
                transform: scale(1.5);
                margin-right: 10px !important;
            }
        }
    }
    header{
        position: fixed !important;
        padding-left: 50px !important;
        padding-right: 50px !important;
        .nav-button{
            display: block !important;
        }
        .menu{
            position: absolute !important;
            top: 0 !important;
            right: -100vw !important;
            height: 100vh !important;
            // width: 90%;
            background: white !important;
            z-index: 10001 !important;
            padding: 4rem;
            padding-top: 110px !important;
            overflow: auto !important;
            transition: all 300ms;
            border-radius: 0 !important;

            &+.mobile-bg{
                position: absolute;
                z-index: 10000;
                background: transparentize($color: #000000, $amount: .5);
                height: 100vh;
                width: 100vw;
                top: 0;
                left: 0;
                transition: all 500ms;
                display: none;
            }
            &.mobileNav{
                right: 0 !important;
                &+.mobile-bg{
                    display: block;
                }
            }
            li{
                text-align: left !important;
                width: 100% !important;
                display: block !important;
                &.nested ul{
                    li a{padding: .6rem 1rem !important;}
                }
                a{
                    padding: .75rem .5rem !important;
                    font-size: 24px !important;
                }
                &.quote{
                    text-align: center !important;
                    margin: 0 !important;
                    margin-top: 3rem !important;
                    a{
                        padding: 1.5rem !important;
                    }
                }
            }
            nav{
                // overflow: auto;
                height: 100%;
            }
            nav>ul>li>a{font-weight: bold !important;}
            nav>ul>li>a::after{content: "" !important;}
            ul{
                flex-direction: column !important;
                align-items: start !important;
                .quote{
                    margin-top: 40px;
                }
                &.subNav{
                    position: relative !important;
                    height: unset !important;
                    box-shadow: none  !important;
                    padding-left: 1.5rem !important;
                }
            }
        }
    }
    .footer-inner-wrapper{
        grid-template-columns: 1fr 1fr !important;
    }
    .contact-us{
        padding: 5rem 2rem !important;
        .contact-tile{
            height: 135px !important;
            width: 190px !important;
            object-fit: cover;
            object-position: bottom right;
        }
        img{
            max-width: unset !important;
            max-height: 225px !important;
        }
        .btn{
            font-size: 20px;
        }
    }
    .partner{
        padding: 3rem 2rem !important;
    }
    .pricing{
        .content-wrapper{
            display: flex !important;
            flex-direction: column !important;
            .card-prices-item-wrapper{
                margin: auto !important;
                padding: 7%% 10% !important;
                .card-prices-item{
                    width: 400px !important;
                    height: 100% !important;
                    h1{
                        font-size: 2.5rem !important;
                    }
                    .price{
                        font-size: 4rem !important;
                    }
                    ul{
                        // list-style: none;
                        // text-align: center;
                        padding-left: 15% !important;
                        li{
                            font-size: 1.25rem !important;
                        }
                    }
                    .btn{
                        font-size: 1.5rem !important;
                    }
                }
                &.middle{
                    // max-width: 600px !important;
                    padding-left: 15% !important;
                    padding-right: 15% !important;
                }
            }
        }
    }
    .blog{
        padding-left: 20px !important;
        padding-right: 20px !important;
        .blog-title{
            font-size: 14px !important;
        }
    }
    .awards{
        .award-wrapper{
            img:not(.tile){width: 200px !important;}
            &.award-2022{
                img:not(.tile){width: 250px !important;}
            }
        }
    }

    section.midbanner.managedservices .content-wrapper{
        display: flex !important;
        flex-direction: column-reverse !important;
    }
    section.midbanner.cloudbased{
        .content-wrapper{
            grid-template-columns: 1fr;
            .right{
                padding: 3.5rem !important;
                padding-top: 5rem !important;
                .tile{
                    left: -20px !important;
                    top: -90% !important;
                }
            }
            .industries{
                width: 100%;
                p{
                    font-size: 1.4rem;
                }
            }
        }
    }
    .article-tag{
        font-size: 1.5rem !important;
    }
    section.midbanner.promovideo {
        padding: 10rem  0 0 !important;
        .content-wrapper{
            grid-template-columns: 1fr;
            .top{
                transform: scale(1);
                .tile{
                    left: -6rem;
                }
            }
            .video-wrapper{
                transform: scale(1);
            }
            .left{
                text-align: center;
                h1{
                    display: inline-block;
                    margin-bottom: 2rem;
                }
            }
        }
    }
    footer{
        padding: 3rem !important;
        span{
            font-size: 12px !important;
        }
        .logo img{
            height: 80px !important;
            margin-bottom: 2rem !important;
        }
        .footer-contact{
            margin-left: 20px;
        }
    }
}


@media screen and (max-width: 900px) {
    footer{
        .logo,
        ul{
            padding-left: 0 !important;
            padding-bottom: 3rem !important;
        }
    }
    // .banner{
    //     .brand{
    //         // padding-left: 40px !important;
    //     }
    // }
    .contact-page{
        grid-template-rows: unset !important;
        grid-template-columns: 1fr !important;
        grid-auto-flow: unset !important;
        padding: 0 !important;
        padding-top: 15.5rem !important;
        // min-height: 100vh !important;
        input,
        select,
        textarea,
        .btn{
            font-size: 26px !important;
        }
        .title{
            text-align: center;
            padding-left: 0 !important;
        }

        .tile:not(.tile-second){
            top: -200px !important;
            left: 382px !important;
        }
        .btn{
            // width: 100%;
            // border-radius: 5px !important;
            font-size: 30px !important;
            padding: 20px 65px;
        }
        .section-form{
            &.body{
                padding: 0 !important;
            }
        }
    }
    .referral-page{
        .content-wrapper{
            grid-template-columns: 1fr !important;
            .right{
                display: none;
            }
            .left{
                padding-right: 0 !important;
            }
            form{
                padding: 50px 100px!important;
            }
        }
    }
    .reasons{
        .tile{
            top: 10px !important;
            right: 10px !important;
        }
        .telavi{
            display: grid !important;
            grid-template-columns: 1fr 1fr !important;
        }
    }
    .home.midbanner{
        .content-wrapper{
            grid-template-columns: 1fr !important;
            .right{
                order: -1;
                margin-bottom: 3rem !important;
            }
        }
    }
    .products .content-wrapper .products-wrapper{
        // grid-template-columns: 1fr !important;
    }
}