@import "colors";

section.not-found{
    color: white;
    text-align: center;
    position: relative;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    .tile{
        height: 300px;
        position: absolute;
        bottom: 10px;
        left: 20px;
    }
    .tile2{
        height: 300px;
        position: absolute;
        top: 120px;
        right: 20px;
        transform: rotate(180deg);
    }
    h1{
        font-size: 100px;
        margin-top: 50px;
        margin-bottom: 10px;
    }
    h3{
        font-size: 30px;
        margin-bottom: 50px;
    }
    h1,
    h3{
        position: relative;
        z-index: 2;
    }
}

section.contact-us{
    // display: none !important;
}

section.thankyou{
    
}