@import "colors";

.IS-title{
    color: $theme-blue;
    text-align: center;
}

.ctc-slogan{
    background-color: white;
}

.IS-content{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr !important;
    .content-container{
        position: relative;
        .content{
            background-color: #d5d6f6;
            padding-top: 30px !important;
            padding-bottom: 30px !important;
            color: #2d32d2;
            border-radius: 20px;
            width: 350px;
            margin: 50px auto;
            line-height: 25px;
            padding: 25px;
            transition: background-color 1s ease-in-out;
            position: relative;
            h1{
                margin-top: 10px;
            }
            .img-container{
                height: 30%;
                margin-bottom: 15px;
            }
            .healthcare-logo{
                width: 16%;
            }
        }
        button{
            padding: 15px;
            border-radius: 15px;
            background-color: $theme-orange;
            position:absolute;
            // transform: translate(572%, -152%);    
            bottom: -25px;
            left: unset;
            right: -20px;

        }
        button:hover{
            cursor: pointer;
        }
    }
}

.first-par{
    margin-top: 10px;
}

.visible {
    max-height: 500px; /* Set a maximum height for the transition */
    transition: max-height 0.5s ease-in-out, margin 0.5s ease-in-out;
    margin-top: 40px;
    margin-bottom: 35px;
    overflow: hidden; /* Hide content that exceeds the max-height */
  }
  
  .hidden {
    max-height: 0;
    transition: max-height 0.5s ease-in-out, margin 0.5s ease-in-out;
    margin-top: 0;
    margin-bottom: 0;
    overflow: hidden; /* Hide the content by setting max-height to 0 */
  }
.invert-content{
    background-color: $theme-blue-darker !important;
    color: $theme-blue-light !important;
    transition: background-color 1s ease-in-out;
    .img-container{
        img{
            filter: brightness(0) invert(1);
            transition: filter 0.5s ease-in-out;
        }
    }
}

.minusbtn{
    padding-top: 10px;
    padding-bottom: 10px;
}

.IS-success{
    background-color: $theme-blue-gray !important;
    .IS-tile-1{
        position: absolute;
        transform: translate(50%, 10px);
    }
    .IS-tile-2{
        position: absolute;
        right: 250px;
        bottom: 50px;
    }
    h1{
        color: $theme-blue !important;
    }
    .IS-content-control{
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
        .control-btns{
            background-color: unset;
            border: none;
        }
        .IS-content-grid{
            display: grid;
            grid-template-columns: 10% 1fr 10%;
            height: 400px;
            width: 80%;
            text-align: center;
            align-items: center;
            padding: 50px 0;
            font-size: 25px;
            font-style: italic;
            font-weight: bold;
            border-style: solid;
            border-radius: 15px;
            border-width: 1px;
            border-color: $theme-blue;
            a{
                margin-top: 100px;
                font-size: 16px;
                font-style: normal;
            }
            .quotation-marks{
                align-self: start;
            }
        }
    }
}

@media screen and (max-width: 1470px){
    .IS-content{
        grid-template-columns: 1fr 1fr !important;
        .content-container{
            .content{
                background-color: #d5d6f6;
                padding-top: 30px !important;
                padding-bottom: 30px !important;
                color: #2d32d2;
                border-radius: 20px;
                width: 350px;
                margin: 50px auto;
                line-height: 25px;
                padding: 25px;
                transition: background-color 1s ease-in-out;
                h1{
                    margin-top: 10px;
                }
                .img-container{
                    height: 30%;
                    margin-bottom: 15px;
                }
                .healthcare-logo{
                    width: 16%;
                }
            }
            button{
                padding: 15px;
                border-radius: 15px;
                background-color: $theme-orange;
                position:absolute;
                transform: translate(630%, -152%);
    
            }
            button:hover{
                cursor: pointer;
            }
        }
    }
}

@media screen and (max-width: 1024px){
    .IS-content{
        .content-container{
            button{
                transform: translate(948%, -150%);
        
            }
        }
    }
}

@media screen and (max-width: 1024px){
    .IS-content{
        .content-container{
            button{
                transform: translate(948%, -150%);
        
            }
        }
    }
}

@media screen and (max-width: 950px){
    .IS-content{
        .content-container{
            button{
                transform: translate(900%, -150%);
        
            }
        }
    }
}

@media screen and (max-width: 435px){
    .IS-content{
        .content-container{
            button{
                transform: translate(835%, -150%);
        
            }
        }
    }
}

@media screen and (max-width: 387px){
    .IS-content{
        .content-container{
            button{
                transform: translate(750%, -150%);
        
            }
        }
    }
}