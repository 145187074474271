// @import url("https://cdn.jsdelivr.net/npm/font-awesome@4.7.0/css/font-awesome.css");
@import "colors";

body,
html{
    height: 100vh !important;
    width: 100% !important;
    // overflow: auto;
    font-family: 'Inter', Arial, sans-serif;
    background: $theme-blue;
    font-size: 16px;
}
:root {
    color-scheme: light only;
}
#root{
    width: 100%;
    overflow-x: hidden;
    position: relative;
}

main{
    overflow-x: hidden;
    // min-height: calc(100vh - 300px);
}

#root section.contact-us:not(.hidden){
    display: block !important;
}
#root section.contact-us.hidden{
    display: none !important;
}


#clickToCallCTA{
    display: none;
    position: fixed;
    right: 0;
    bottom: 10%;
    z-index: 999;
    border-radius: 10px 0 0 10px;
    padding: 15px 19px;
    background: rgb(29, 176, 29);
    // transform: translate(-100%, -50%) rotate(-90deg);
    height: 200px;
    width: 50px;
    transform: scale(1);
    transform-origin: right;
    a{
        display: inline-block;
        // width: 100%;
        text-wrap: nowrap;
        height: 100%;
        transform: rotate(-90deg);
        right: -3px;
        position: relative;
        color: white;
        font-weight: bold;
        svg{
           margin-right: 10px; 
        }
    }
    // a{
    //     display: inline-block;
    //     svg{
    //         height: 52px;
    //         width: 48px;
    //         path{
    //             transform: scale(4);
    //         }
    //     }
    // }
}

// .loader{
//     position: fixed;
//     height: 100vh;
//     width: 100vw;
//     left: 0;
//     top: 0;
//     background: white;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     z-index: 9999;
//     .spinner{
//         position: absolute;
//         animation: loader-spin 3s infinite;
//         z-index: 1;
//         height: 100px;
//         &.reverse{
//             z-index: 0;
//             animation-direction: reverse;
//             display: none;
//         }
//     }
// }

#c2c{
    padding-top: 135%;
    position: relative;

    #endCall{
        position: absolute;
        z-index: 1;
        bottom: 5%;
        left: 50%;
        transform: translateX(-50%);
        background: $theme-red;
        color: white;
        font-size: 20px;
        border-radius: 5px;
        padding: 5px 15px;
        transition: background 200ms;
        &:hover{
            transition: background 200ms;
            background: darken($theme-red, 10%);
        }
    }
    iframe{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
    }
}


.logo-independent{
    position: absolute;
    padding: 2rem 0 0 5rem;
    top: 0;
    left: 0;
    z-index: 998;
    a{
        display: block;
    }
    img{
        height: 50px;
        &.logo-blue{
            position: absolute;
            left: 0;
            opacity: 0;
            transition: all 300ms;
        }
    }
}

.overlay-loader{
    .loader-wrapper{
        opacity: .7;

    }
}

.loader {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: block;
    position: relative;
    background: $theme-blue-light;
    box-shadow: -30px 0 $theme-blue-light, 30px 0 $theme-blue-light;
    box-sizing: border-box;
    animation: shadowPulse 2s linear infinite;
    &-wrapper{
        position: fixed;
        z-index: 999999;
        height: 100vh;
        width: 100%;
        background: white;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

.popup{
    position: fixed;
    z-index: 9998;
    height: 100%;
    width: 100%;
    background: transparentize($color: #000000, $amount: 0.5);
    padding: 0;
    padding-top: 5%;
    opacity: 1;
    animation: fadeIn ease-in 500ms;
    &.hidden{
        transition: opacity 500ms;
        opacity: 0;
    }
    &-container{
        width: 437px;
        margin: auto;
        position: relative;
        background: $theme-blue;
        border-radius: 5px;
        overflow: hidden;
        color: white;
        img{
            height: auto;
            width: 100%;
        }
        .body-container{
            padding: 1rem;
            text-align: center;
            h1{
                font-size: 26px;
            }
            .btn{
                padding: 10px 20px;
            }
            input{
                display: block;
                border: 0;
                padding:5px 10px;
                max-width: 300px;
                text-align: center;
                font-style: italic;
                color: $theme-blue-secondary;
                font-size: 20px;
                margin: 10px auto 20px;
            }
        }
        .close-popup{
            position: absolute;
            top: 5px;
            right: 10px;
            color: $theme-blue;
            font-size: 25px;
            font-weight: bold;
            cursor: pointer;
            &:hover{
                color: $theme-orange;
            }
        }
        // .btn{
        //     position: absolute;
        //     bottom: 95px;
        //     right: 50px;
        //     font-size:14px;
        //     padding: 10px 25px;
        // }
    }
}
  
@keyframes fadeIn {
    0%{
        background: transparentize($color: #000000, $amount: 1);
    }
    100%{
        background: transparentize($color: #000000, $amount: 0.5);
    }
}

@keyframes shadowPulse {
    33% {
        background: $theme-blue-light;
        box-shadow: -30px 0 $theme-blue, 30px 0 $theme-blue-light;
    }
    66% {
        background: $theme-blue;
        box-shadow: -30px 0 $theme-blue-light, 30px 0 $theme-blue-light;
    }
    100% {
        background: $theme-blue-light;
        box-shadow: -30px 0 $theme-blue-light, 30px 0 $theme-blue;
    }
}



.imgbanner{
    height: 100%;
    width: 100%;
    object-fit: cover;
    object-position: center bottom;
    &-wrapper,
    &-wrapper [class*="css-"]{
        height: 100%;
        width: 100%;

    }
}

header{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 2rem 0 5rem;
    top: 0;
    right: 0;
    // width: 100%;
    font-weight: 500;
    z-index: 999;
    transition: all 300ms;
    position: fixed;
    &.opened{
        width: 100%;
        justify-content: end;
    }
    &.header-alt{
        .quote{
            position: fixed;
            right: 1rem;
            top: 2rem;
            cursor: pointer;
            a{
                cursor: pointer;
                display: block;
                border-radius: 1000px;
                height: 100%;
                color: black;
                background: $theme-blue-darker;
                color: white;
                font-weight: 600;
                padding: 1rem 2rem;
                position: relative;
                overflow:hidden;
                z-index: 1;
                box-shadow: 2px 2px 10px 0 rgba(0, 0, 0, 0.2);
                transition:         0.08s ease-in;
                -o-transition:      0.08s ease-in;
                -ms-transition:     0.08s ease-in;
                -moz-transition:    0.08s ease-in;
                -webkit-transition: 0.08s ease-in;

                &:hover {
                    color: $theme-blue !important;
                    &:before {
                        top: 0;
                    }
                } 
                &:before {
                    content: "";
                    position: absolute;
                    background: $theme-orange !important;
                    bottom: 0;
                    left: 0;
                    right: 0;
                    top: 100%;
                    z-index: -1;
                    -webkit-transition: top 0.09s ease-in;
                }
                &:active{
                    transform: scale(.95);
                }
            }
        }
        .logo img{
            height: 50px;
        }
        // #header-logo.blue{
        //     display: none;
        // }
    }
    &.fixed{
        position: fixed;
        background: white;
        padding-bottom: 1rem !important;
        animation: slide 0.3s;
        box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.287);
        .menu{
            box-shadow: none !important;
        }
        .logo-blue{
            opacity: 1 !important;
        }
        .menu nav ul li.nested:hover ul.subNav{
            top: 100% !important;
        }
    }
    .nav-button{
        position: relative;
        width: 60px;
        height: 60px;
        z-index: 10002;
        cursor: pointer;
        transition: all 300ms;
        display: none;
        background: black;
        padding: 10px;
        border-radius: 4px;
        &.opened{
            background: none !important;
            div{
                background: red;
                transform: translate(-50%, -50%) rotate(45deg);
                top: 50% !important;
                left: 50% !important;
                &:nth-child(2){
                    transform: translate(-50%, -50%) rotate(-315deg);
                }
                &:nth-child(3){
                    transform: translate(-50%, -50%) rotate(-45deg);
                }
            }
            &:hover{
                div{
                    background: $theme-orange;
                }
            }
        }
        div{
            position: absolute;
            height: 8px;
            width: calc(100% - 20px);
            background: white;
            transform: translate(-50%, -50%);
            top: 50%;
            left: 50%;
            transition: all 300ms;
            border-radius: 2px;
            &:first-child{
                top: calc(50% - 10px);
            }
            &:last-child{
                top: calc(50% + 10px);
            }
        }
    }
    .mobile-bg{
        display: none;
    }
    .logo{
        position: relative;
        a{
            display: block;
        }
        img{
            height: 70px;
            &.logo-blue{
                position: absolute;
                left: 0;
                opacity: 0;
                transition: all 300ms;
            }
        }
    }
    .menu{
        display: inline-block;
        // box-shadow: 0 5px 10px 0 rgba(128, 128, 128, 0.25);
        filter: drop-shadow(0 5px 10px rgba(128, 128, 128, 0.25));
        border-radius: 100px;
        nav{
            ul{
                background: white;
                list-style: none;
                display: flex;
                flex-direction: row;
                border-radius: 1000px;
                padding: 5px;
                margin: 0;
                align-items: center;
                a{
                    display: block;
                    padding: 10px 10px;
                    border-radius: 1000px;
                    height: 100%;
                    color: black;
                    position: relative;
                    font-size: 14px;
                    cursor: pointer;
                }
                .quote{
                    a{
                        svg{
                            vertical-align: middle;
                            margin-right: 5px;
                            path{
                                transition:         0.08s ease-in;
                                -o-transition:      0.08s ease-in;
                                -ms-transition:     0.08s ease-in;
                                -moz-transition:    0.08s ease-in;
                                -webkit-transition: 0.08s ease-in;
                            }
                        }
                        background: $theme-blue;
                        color: white;
                        font-weight: 600;
                        padding: 10px 20px;
                        position: relative;
                        overflow:hidden;
                        z-index: 1;
                        transition:         0.08s ease-in;
                        -o-transition:      0.08s ease-in;
                        -ms-transition:     0.08s ease-in;
                        -moz-transition:    0.08s ease-in;
                        -webkit-transition: 0.08s ease-in;

                        &:hover {
                            color: $theme-blue !important;
                            &:before {
                                top: 0;
                            }
                            svg path{
                                fill: $theme-blue !important;
                            }
                        } 
                        &:before {
                            content: "";
                            position: absolute;
                            background: $theme-orange !important;
                            bottom: 0;
                            left: 0;
                            right: 0;
                            top: 100%;
                            z-index: -1;
                            -webkit-transition: top 0.09s ease-in;
                        }
                        &:active{
                            transform: scale(.95);
                        }
                    }
                }
                li:not(.quote){
                    margin-right: .5rem;
                    a{
                        border-radius: 10px;
                    }
                    a:hover{
                        color: $theme-blue-light;
                        background: $theme-blue;
                    }
                }
                li.nested{
                    position: relative;
                    &>a:after{
                        font-family: "FontAwesome";
                        content: "\f0d7";
                        margin-left: 10px;
                    }
                    &:hover{
                        // ul.subNav{
                        //     top: 90% !important;
                        //     opacity: 1 !important;
                        // }
                    }
                    ul.subNav{
                        &>li:first-child{
                            margin: 0;
                            a{
                                padding-top: calc(5px + 1rem);
                            }
                        }
                        &>li:last-child{
                            a{
                                padding-bottom: calc(5px + 1rem);
                            }
                        }
                        position: absolute;
                        display: flex;
                        flex-direction: column;
                        text-align: left;
                        align-items: flex-start;
                        border-radius: 0 0 15px 15px;
                        left: -5px;
                        padding: 0;
                        z-index: 999;
                        height: 0px;
                        top: 100%;
                        overflow: hidden;
                        transition: 300ms ease-out height;
                        // box-shadow: 0 5px 10px 0 rgba(128, 128, 128, 0.75);
                        width: unset;
                        a{
                            text-wrap: nowrap;
                            padding-left: calc(5px + 1.25rem);
                            padding-right: calc(5px + 1.25rem);
                        }
                        .nest-wrapper{
                            padding: 20px 20px 40px 20px;
                            .nest-header{
                                margin-bottom: 3px;
                                img{
                                    height: 30px;
                                    width: 30px;
                                    object-fit: contain;
                                    object-position: center;
                                    margin-right: 10px;
                                }
                                span{
                                    color: black;
                                }
                            }
                            .nest-items{
                                border-left: 2px solid $theme-blue;
                                margin-left: 14px;
                                padding-left: 15px;
                                margin-bottom: 20px;
                                a{
                                    padding: 5px 10px;
                                    // font-size: 12px;
                                    &:hover{
                                        background: none !important;
                                        color: $theme-blue;
                                    }
                                }
                            }
                        }
                    }
                }
                &>li:first-child{
                    margin-left: 1rem;
                }
                
            }
        }
    }
}

section{
    position: relative;
    padding: 7.5rem 12%;
    .title{
        margin-bottom: 20px;
        // background: white;
        // display: inline-block;
        // margin: 0;
    }
    &.home{
        .btn{
            font-size: 20px !important;
        }
        .content-wrapper{
            overflow-x: hidden;
        }
        .brand{
            display: grid;
            grid-template-rows: 60% 40%;
            div:first-child{
                align-self: end;
            }
        }
    }
    &.banner{
        $innerpage-banner-height: 400px;
        background: $theme-blue;
        padding: 0 !important;
        .swiper-slide {
            height: auto !important;
        }
        .swiper_slide{
            &--inner{
                .left{
                    flex-direction: column;
                    padding-top: 0 !important;
                    .brand{
                        padding-top: 8rem;
                        padding-bottom: 2rem;
                    }
                }
                .logo{
                    padding-top: 30px;
                    img{
                        height: 50px;
                    }
                }
                .tag{
                    display: inline-block;
                    font-size: .8rem;
                    background: black;
                    color: white;
                    padding: 5px 10px;
                    margin-bottom: 10px;
                    &.empty{
                        opacity: 0;
                        height: 25.5px;
                    }
                }
            }
            &-orange{
                background: $theme-orange;
                p, h3{
                    color: black;
                }
                .title{
                    color: $theme-blue;
                }
            }
        }
        &-innerpage{
            max-height: $innerpage-banner-height;
            [class*='css-']{
                height: 100% !important;
            }
            .left{
                .tile{
                    img{
                        width: 200px !important;
                        margin-right: 1rem;
                        position: relative;
                        margin-top: -70px;
                    }
                }
                .brand{
                    align-self: end;
                }
            }
            .right{
                max-height: $innerpage-banner-height;
                    img{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
            }
            .content-wrapper{
                grid-template-columns: 40% 1fr !important;
                .tile{
                    display: block !important;
                    height: 100% ;
                    img{
                        // width: 100px !important;
                        // margin-top: 0;
                        margin-left: -70px;
                    }
                }
                .brand{
                    padding-left: .5rem !important;
                    h1{
                        font-size: 32px !important;
                    }
                }
            }
        }
        .content-wrapper{
            display: grid;
            grid-template-columns: 40% 1fr;
            color: white;
            height: 100%;
            .left{
                padding: 10rem 2rem 2rem 5rem;
                display: flex;
                h3{
                    font-weight: normal;
                }
                .tile{
                    img{
                        width: 130px;
                        margin-right: 1rem;
                    }
                }
                .cta{
                    // display: none;
                    padding-top: 2rem;
                    a{
                        margin-right: 1rem !important;
                        margin-top: .5rem;
                        padding: 10px 30px !important;
                        // font-size: 1px !important;
                    }
                    img{
                        margin-right: 15px;
                        height: 20px;
                    }
                }
            }
            .right{
                
                img{
                    width: 100%;
                }
            }
        }
    }
    &.products{
        background: #fff;
        padding-left: 1% !important;
        padding-right: 1% !important;
        .card-trigger{
            cursor: default !important;
            // grid-template-rows: 100px 1fr 50px !important;
        }
        .open-btn{
            display: none !important;
        }
        .content-wrapper{
            overflow-y: hidden;
        }
        .title{
            color: $theme-blue;
            &.text-left{
                padding-left: 14%;
            }
        }
        .tile{
            position: absolute;
            top: 1rem;
            left: 1rem;
            img{
                width: 150px;
            }
        }
        .products-wrapper{
            display: grid;
            grid-template-columns: repeat(3, 33.3%);
            max-width: 1200px;
            margin: auto;
            .products-item{
                cursor: pointer;
                background: $theme-blue-light;
                margin: 0 1rem;
                position: relative;
                color: $theme-blue;
                border-radius: 1.5rem;
                padding: 1.5rem 1.5rem;
                transition: all 300ms;
                margin-bottom: 50px;
                text-align: center;
                h2{
                    color: $theme-dark;
                    font-size: .75em;
                    height: 45px;
                    margin-top: 5px;
                }
                .product-cta{
                    display: block;
                    margin-top: 10px;
                    padding: 10px 30px !important;
                    float: right;
                }
                .title{
                    font-size: 2rem !important;
                    font-weight: bold;
                }
                .subtitle{
                    font-size: 1.1rem;    
                    height: 100px;
                    font-weight: normal;
                }
                .btn{
                    // margin-top: 50px;
                    font-size: 1rem !important;
                    padding: 10px 20px;
                    display: block;
                    width: 150px;
                    display: block;
                    text-align: center;
                }
                .Collapsible__contentInner {
                    div{
                        min-height: 150px;
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                    }
                    p{
                        font-size: 16px !important; 
                    }
                }
                &.open{
                    padding-bottom: 2rem;
                    background: $theme-blue;
                    color: #fff;
                    .title{
                        color: white;
                    }
                    .open-btn .line.vertical{
                        transform: translate(-50%, -50%) !important;
                    }
                    .icon-wrapper img{
                        &.default{
                            opacity: 0;
                        }
                        &.icon-open{
                            opacity: 1 !important;
                        }
                    }
                }
                .card-trigger{
                    // min-height: 250px;
                    display: grid;
                    grid-template-rows: 100px 1fr;
                    justify-content: space-between;
                    min-height: 230px;
                    div.title{
                        font-size: 20px !important;
                        margin: 0 !important;
                    }
                    .icon-wrapper{
                        position: relative;
                        height: 65px;
                        width: 75px;
                        img{
                            object-fit: contain;
                            height: 100%;
                            width: 100%;
                            position: absolute;
                            left: 0;
                            transition: all 100ms;
                            &.default{
                                opacity: 1;
                            }
                            &.icon-open{
                                opacity: 0;
                            }
                        }
                    }
                    .open-btn{
                        height: 50px;
                        width: 50px;
                        background-color: $theme-orange;
                        border-radius: 15px;
                        position: absolute;
                        bottom: -15px;
                        right: -15px;
                        cursor: pointer;
                        .line{
                            height: 7px;
                            width: 30px;
                            border-radius: 100px;
                            background: white;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            &.vertical{
                                transform: translate(-50%, -50%) rotate(-90deg);
                                transition: all 100ms;
                            }
                        }
                    }
                }
            }
            &.nodesc{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                .products-item{
                    position: relative;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 200px;
                    padding-bottom: 40px;
                    margin: 0 10px 30px;
                    cursor: default;
                    img{
                        height: 80px;
                        width: 80px;
                    }
                    h1{
                        margin: 0 0;
                    }
                    a{
                        position: absolute;
                        bottom: -15px;
                        width: 80%;
                    }
                }
            }
        }
    }
    &.reasons{
        background-color: $theme-blue;
        .btn{
            margin: auto;
            font-size: 24px;
            padding: 1rem 1.5rem;
            margin-top: 2rem;
        }
        .tile{
            position: absolute;
            top: 7.5rem;
            right: 5rem;
            img{
                height: 150px;
            }
        }
        .content-wrapper{
            color: #fff;
            text-align: center;
            h2{
                max-width: 800px;
                margin: 3rem auto;
                font-weight: normal;
                font-size: 20px;
            }
        }
        .telavi{
            display: flex;
            margin: auto;
            width: 100%;
            justify-content: space-between;
            text-align: center;
            .telavi-item{
                img{
                    width: 250px;
                    margin-bottom: 1rem;
                }
            }
        }
    }
    &.midbanner{
        background-color: $theme-blue-secondary;
        color: white;
        .content-wrapper{
            display: grid;
            grid-template-columns: 45% 1fr;
            align-items: center;
            .left {
                padding-right: 2rem;
                & > div > *{
                    margin-bottom: 20px;
                }
            }
            h1{
                font-size: 40px;
            }
            p{
                font-size: 18px;
            }
            img{
                width: 100%;
            }
            .btn{
                margin: 2rem 0 0 !important;
            }
        }
        &-6040{
            .content-wrapper{
                grid-template-columns:1fr 40%;
            }
        }
        &-5050{
            .content-wrapper{
                grid-template-columns:1fr 45%;
                .midbanner-img{
                    height: 100%;
                    width: 100%;
                    img{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center bottom;
                    }
                }
            }
        }
        &-4060{
            padding: 0 !important;
            &.r-img {
                .right{
                    height: 100%;
                    padding: 0 !important;
                }
                .left{
                    padding: 3.5rem;
                }
            }
            &.l-img {
                .left{
                    height: 100%;
                    padding: 0 !important;
                }
                .right{
                    padding: 3.5rem;
                }
            }
            .content-wrapper{
                grid-template-columns: 40% 1fr;
                h4{
                    font-size: 20px;
                }
                h1, 
                h4, 
                p{
                    margin-bottom: 2rem;
                }
                .midbanner-img{
                    height: 100%;
                    width: 100%;
                    img{
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                        object-position: center bottom;
                    }
                }
            }
        }
    }
    &.partner{
        background: $theme-blue-light;
        color: $theme-blue;
        text-align: center;
        padding: 3rem 12% 5rem;
        .partners-img{
            max-width: 600px;
            width: 100%;
            margin-top: 3rem;
        }
    }
    &.awards{
        background: white;
        padding-bottom: 2rem;
        .title{
            color: $theme-blue;
        }
        .content-wrapper{
            display: flex;
            align-items: end;
            justify-content: center;
            margin: auto;
            img{
                width: 300px;
            }
            .award-wrapper{
                display: flex;
                flex-direction: column;
                margin: 0 1rem;
                &.award-2020>div{
                    justify-content: flex-end;
                    text-align: right;
                }
                &.award-2022{
                    img{
                        width: 350px;
                    }
                }
                .tile{
                    height: 50px;
                    width: auto;
                    text-align: right;
                    align-self: flex-end;
                    margin-top: 20px;
                }
            }
        }
    }
    &.success{
        background: $theme-blue-light;
        // padding-top: 20px !important;
        .title{
            color: $theme-blue;
            margin-bottom: 4rem;
            text-align: center;
        }
        .success-card{
            border: 1px solid $theme-blue;
            border-radius: 20px;
            display: flex;
            padding: 1rem 3rem;
            text-align: center;
            max-width: 800px;
            margin: auto;
            position: relative;
            [class*="-quote"]{
                color: $theme-orange;
                font-size: 100px;
                font-weight: bold;
                letter-spacing: -7px;
            }
            .tile{
                position: absolute;
                height: 150px;
                img{
                    height: 100%;
                }
                &-tl{
                    top: -60px;
                    left: -4rem;
                }
                &-br{
                    bottom: -60px;
                    right: -4rem;
                    img{
                        transform: rotate(180deg);
                    }
                }
            }
            .quote{
                padding: 2rem 1rem;
                font-size: 26px;
                font-weight: bold;
                // font-style: italic;
            }
            .btn{
                margin-top: 30px;
                font-size: 20px;
            }
        }
    }
    &.pricing{
        background: $theme-blue;
        color: white;
        padding-left: 50px;
        padding-right: 50px;
        .content-wrapper{
            text-align: center;
            overflow-y: hidden;
            .card-prices{
                margin: auto;
                display: inline-flex;
                &-item{
                    // height: 400px;
                    width: 250px;
                    color: white;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    h1:not(.price){
                        font-size: 30px;
                    }
                    &>div{
                        min-height: 400px;
                        box-sizing: border-box;
                    }
                    &-wrapper{
                        margin: 0 1rem;
                        background: $theme-blue-secondary;
                        padding: 2rem 4rem;
                        align-self:center;
                        &.middle{
                            background-color: white!important;
                            padding: 5rem 4rem;
                            h1:not(.price),
                            li{
                                color: black !important;
                            }
                        }
                    }
                    &>div>*{
                        margin-bottom: 1rem;
                    }
                    .price{
                        margin: 2rem 0;
                    }
                    ul{
                        text-align: left;
                        padding-left: 1rem;
                        margin-top: 0;
                        li{
                            padding-bottom: .75rem;
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .tile{
            position: absolute;
            height: 120px;
            display: inline-block;
            &-tl{
                top: 2rem;
                left: .5rem;
            }
            &-br{
                bottom: 2rem;
                right: .5rem;
            }
            &-bl{
                bottom: 2rem;
                left: .5rem;
            }
            &-tr{
                top: 2rem;
                right: .5rem;
            }
            img{
                height: 100%;
            }
        }
    }
    &.blog{
        background: white;
        .title{
            color: $theme-blue;
        }
        .content-wrapper{
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
            .blog-right{
                display: grid;
                grid-template-rows: 1fr 1fr;
                grid-gap: 10px;
                .blog-item{
                    padding-top: unset;
                    height: 100%;
                    width: 100%;
                }
            }
            .blog-bottom{
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 10px;
            }
            .blog-item{
                padding-top: 100%;
                display: block;
                position: relative;
                cursor: pointer;
                img{
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                    position: absolute;
                    top: 0;
                    left: 0;
                    transition: all 300ms ease-in;
                }
                .details{
                    width: 100%;
                    padding: 20px;
                    position: absolute;
                    z-index: 1;
                    bottom: 0;
                    background: transparentize($color: #fff, $amount: .6);
                    transition: all 300ms ease-in;
                    height: 124px;
                    .date{
                        font-size: 12px;
                        margin-bottom: 10px;
                    }
                    h1{
                        max-height: 60px;
                        text-overflow: ellipsis; 
                        overflow: hidden;
                        // white-space: nowrap;
                    }
                }
                &:hover{
                    img{
                        filter: brightness(.8);
                    }
                    .details{
                        background: transparentize($color: #fff, $amount: .25);
                    }
                }
            }
        }
    }
    &.referralAndPartnerBanner{
        padding: 0 !important;
        background: $theme-blue-secondary;
        .content-wrapper{
            display: grid;
            grid-template-columns: 1fr 1fr;
            background: $theme-blue-secondary;
            &>div{
                max-height: 500px;
            }
            .left,
            .right{
                height: 100%;
                position: relative;
                background: $theme-blue-secondary;
                .text{
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    padding: 30px;
                    color: $theme-blue;
                    text-align: center;
                    h1{
                        font-size: 2.1em;
                    }
                    h2{
                        font-size: 1.4em;
                        font-weight: normal;
                    }
                    a{
                        margin-top: 30px;
                    }
                }
            }
        }
        img{
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }
    &.promovideo{
        background: white;
        color: $theme-blue;
        padding-top: 5rem;
        padding-bottom: 5rem;
        padding-left: 0;
        padding-right: 0;
        .content-wrapper{
            // display: block;
            // flex-direction: column;
        }
        .top{
            display: flex;
            justify-content: center;
            transform: scale(.8);
            h1{
                position: relative;
                padding-bottom: 2rem;
                .tile{
                    position: absolute;
                    height: 100px;
                    top: -3.5rem;
                    left: -68px;
                    transform: translate(-50%, -50%);
                    img{
                        height: 100%;
                        width: auto;
                    }
                }
            }
        }
        .video-wrapper{
            position: relative;
            width:100%;
            padding-bottom: 56.25%; /* 16:9 */
            height: 0;  
            transform: scale(.9);          
            .video{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
    &.latestnews{
        background: #fff;
        margin: auto;
        .content-wrapper{
            max-width: 1000px;
            margin: auto;
        }
        .see-more{
            // color: $theme-orange;
            float: right;
            // margin-top: 20px;
            margin-right: 30PX;
            font-weight: bold;
        }
        .news-header{
            color: $theme-blue-darker;
        }
        hr{
            height: 5px;
            border: none;
            margin: 10px 0 40px;
            background-color: $theme-orange;
        }
        .news-tiles{
            display: grid;
            grid-template-columns: 40% 1fr 1fr;
            .news-item{
                padding: 0 20px;
                &.highlight{
                    grid-row: 1/span 2;
                    .news-image{
                        height: 300px;
                    }
                }
                .news-image{
                    width: 100%;
                    height: 0;
                    position: relative;
                    padding-top: 65%;
                    img{
                        height: 100%;
                        width: 100%;
                        position: absolute;
                        object-fit: cover;
                        object-position: center;
                        top: 0;
                        left: 0;
                    }
                }
                .news-title{
                    display: -webkit-box;
                    height: 45px;
                    margin: 10px 0;
                    color: $theme-blue;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
                .news-preview{
                    display: -webkit-box;
                    height: 80px;
                    -webkit-line-clamp: 4;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-weight: normal !important;
                    text-overflow: ellipsis;
                }
            }
        }
    }
    &.managedservices{
        background: $theme-blue-gray;
        color: black;
        h1{
            color: $theme-blue-darker;
            font-size: 30px !important;
        }
        p{
            font-size: 22px !important;
        }
        .cta{
            // tex
        }
    }
    &.cloudbased{
        background: $theme-blue;
        h1{
            color: white !important;
            position: relative;
            font-size: 30px !important;
        }
        .subtitle{
            color: white;
            font-size: 20px !important;
            margin-top: 10px;
            font-weight: normal;
        }
        h4,
        p{
            // color: $theme-orange;
            max-width: 700px;
        }
        .right{
            padding: 0 5rem !important;
        }
        .left{
            max-height: 600px;
        }
        .industries{
            list-style: none;
            margin: 0;
            padding: 0;
            display: grid;
            grid-template-columns: 50% 50%;
            max-width: 600px;
            li{
                display: flex;
                align-items: center;
                padding: 10px;
            }
            p{
                margin: 0;
                font-weight: bold;
                font-size: 20px;
            }
            img{
                height: 50px;
                width: 50px;
                margin-right: 10px;
                object-fit: contain;
                object-position: left;
            }
        }
    }
    &.thankyou{
        text-align: center;
        color: $theme-blue-light;
        position: relative;
        min-height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .referralCode{
            // color: $theme-orange;
            filter: drop-shadow(4px 4px 0 black);
            // text-shadow: -2px -2px 0 #000, 2px 2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000;
        }
        .tile{
            height: 300px;
            position: absolute;
            bottom: 10px;
            left: 20px;
        }
        .tile2{
            height: 300px;
            position: absolute;
            top: 120px;
            right: 20px;
            transform: rotate(180deg);
        }
        .thankyou-image{
            height: 300px;
        }
        h1{
            font-size: 50px;
            color: white;
            margin-bottom: 20px;
        }
        p{
            font-size: 20px;
            margin-bottom: 10px;
        }
        .btn{
            margin-top: 50px;
        }
        .thankyou-image, h1, p, .btn{
            position: relative;
            z-index: 2;
            filter: drop-shadow(2px 2px 5px transparentize($theme-blue-darker, .75));
        }
    }
}

.article-tag{
    display: inline-block;
    font-size: 0.8rem;
    background: black;
    color: white;
    padding: 5px 10px;
    margin-bottom: 10px;
    margin-right: 10px;
    &.news-and-pr{
        background: $theme-orange;
    }
    &.news-and-pr::before{
        content: "News and PR";
    }
    &.service-spotlight{
        background: $theme-red;
    }
    &.service-spotlight::before{
        content: "Service Spotlight";
    }
    &.tips-and-advice{
        background: $theme-purple;
    }
    &.tips-and-advice::before{
        content: "Tips and Advice";
    }
}

.contact-us{
    padding: 8rem 2rem;
    background: white;
    color: black;
    .background{
        display: flex;
        position: absolute;
        height: 100%;
        top: 0;
        left: 0;
        width: 100%;
        justify-content: space-between;
        img{
            height: 90%;
            max-height: 350px;
        }
        .right{
            display: flex;
            align-items: end;
            height: 100%;
        }
    }
    .content-wrapper{
        position: relative;
        z-index: 1;
        max-width: 600px;
        margin: auto;
        text-align: center;
        &>*{
            margin-bottom: 1rem;
        }
    }
}
footer{
    background: black;
    padding: 4rem 5.5rem 7rem;
    font-size: 20px;
    font-weight: 500;
    position: relative;
    overflow-x: hidden;
    min-height: 300px;
    .footer-inner-wrapper{
        position: relative;
        display: inline-flex;
        flex-direction: row;
        z-index: 1;
    }
    .footer-bg-1,
    .footer-bg-wrapper{
        position: absolute;
        top: .5rem;
        right: .5rem;
        max-height: 250px;
    }
    .footer-contact{
        h3{
            font-weight: normal;
        }
        ul li{
            display: flex;
            align-items: center;
            img{
                height: 20px;
                margin-right: 10px;
            }
            span{
                font-size: 1rem;
            }
        }
    }
    .logo{
        a{
            display: block;
        }
        img{
            height: 100px;
            margin-top: 1rem;
            margin-bottom: 3rem;
        }
        span{
            color: white;
        }
    }
    ul{
        list-style: none;
        color: white;
        margin: 0;
        padding-left: 5rem;
        li{
            padding: .75rem 0;
        }
    }
    .socmed{
        padding-top: 2rem;
        img{
            height: 50px;
            margin-right: .5rem;
        }
    }
}

// General
.btn{
    display: inline-block !important;
    padding: 15px 40px !important;
    border-radius: 100px !important;
    font-weight: 500 !important;
    cursor: pointer !important;
    transition: all 300ms !important;
    border: none;
    &:disabled{
        background: grey !important;
        cursor: default !important;
        &:hover{
            background: grey !important;
            color: white !important;
            filter: none !important;
        }
    }
    &-black{
        background-color: black !important;
        color: white !important;
        &:hover{
            background-color: $theme-orange !important;
            color: $theme-blue !important;
            filter: drop-shadow(0 5px 3px transparentize(black, .75)) !important;
        }
    }
    &-black-alt{
        background-color: black !important;
        color: white !important;
        &:hover{
            background-color: $theme-blue !important;
            // color: $theme-blue !important;
            filter: drop-shadow(0 5px 3px transparentize(black, .75)) !important;
        }
    }
    &-blue{
        background-color: $theme-blue !important;
        color: white !important;
        &:hover{
            background-color: white !important;
            color: $theme-blue !important;
            filter: drop-shadow(0 5px 3px transparentize(black, .75)) !important;
        }
    }
    &-white{
        background-color: white !important;
        color: black !important;
        &:hover{
            background-color: black !important; 
            color: $theme-orange !important;
            filter: drop-shadow(0 5px 3px transparentize(black, .75)) !important;
        }
    }
    &-orange{
        background-color: $theme-orange !important;
        color: white !important;
        &:hover{
            background-color: $theme-blue !important;
            filter: drop-shadow(0 5px 3px transparentize(black, .75)) !important;
        }
    }
}

.telavi-cookie{
    background: transparentize(black, .15) !important;
    // color: white !important;
    // flex-direction: column !important;
    // // height: 300px !important;
    // width: 320px !important;
    // border-radius: 10px !important;
    // box-shadow: 5px 5px 0 0 $theme-dark;
    // bottom: 20px !important;
    // right: 20px !important;
    // left: unset !important;
    // padding: 20px;
    // align-items: center !important;
    // &-content{
    //     flex-direction: column !important;
    //     flex: auto !important;
    //     display: flex !important;
    //     align-items: center;
    // }
    // .cookie-icon{
    //     height: 100px;
    //     width: 100px;
    //     margin-bottom: 20px;
    // }
    .btn-accept{
        padding: 10px 30px !important;
        font-weight: bold !important;
    //     // color: black !important;
    //     // &:hover{
    //     //     color: white !important;
    //     //     background-color: $theme-orange !important;
    //     // }
    }
}

.title{
    font-size: 2.5rem;
    &-orange{
        color: $theme-orange;
    }
    &-blue{
        color: $theme-blue;
    }
}

form{
    .book-appointment-picker{
        display: flex;
        // .react-datepicker-wrapper{
        //     width: 100%;
        // }
        .react-datepicker-wrapper{
            width: 49%;
            &:first-child{
                margin-right: 2%;
            }
        }
    }
    input:not([type="submit"]),
    textarea,
    select{
        resize: none;
        display: block;
        width: 100%;
        // border: 2px solid $theme-orange;
        border: none;
        background: white;
        margin-bottom: 1rem;
        padding: 1rem;
        font-size: 16px;
        font-family: 'Inter', Arial, sans-serif;
        &:focus-visible{
            outline: $theme-blue auto 4px !important;
        }
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: black;
            opacity: 1; /* Firefox */
          }
          
          :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: black;
          }
          
          ::-ms-input-placeholder { /* Microsoft Edge */
            color: black;
          }
    }
    select{
        cursor: pointer;
        background-color: white;
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
        background-repeat: no-repeat;
        background-position-x: 98%;
        background-position-y: 50%;
        padding-right: 2rem;
    }
    textarea{
        min-height: 200px;
    }
    .btn{
        font-size: 16px;
    }
}

@keyframes loader-spin {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
}

@keyframes slide {
    0% {
      opacity: 0;
      transform: translateY(-50px);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }