@import "colors";

.banner.inner-page{
    .left{
        position: relative;
    }
    .tile{
        position: absolute;
        bottom: 10px;
        left: 20px;
        img{
            height: 100% !important;
            width: auto !important;   
        }
    }
    .title{
        color: $theme-blue;
        background: white;
        border-radius: 10px;
        padding: 10px 20px;
    }
}

.news-and-articles{
    background: white;
    .content-wrapper{
        max-width: 1200px;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 1fr;
        .naa{
            &-item{
                padding: 20px;
                padding-bottom: 40px;
                display: block;
            }
            &-image{
                overflow: hidden;
                width: 100%;
                height: 0;
                position: relative;
                padding-top: 55.1%;
                img{
                    height: 100%;
                    width: 100%;
                    position: absolute;
                    object-fit: cover;
                    object-position: center;
                    top: 0;
                    left: 0;
                    z-index: 2;
                }
                .backthumb{
                    object-fit: cover;
                    z-index: 1;
                    filter: blur(5px);
                    display: none;
                }
            }
            &-title{
                display: -webkit-box;
                height: 60px;
                margin: 10px 0;
                text-transform: uppercase;
                color: $theme-blue;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &-preview{
                display: -webkit-box;
                height: 60px;
                margin: 10px 0;
                font-weight: normal;
                color: black;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
}

.article{
    background: white;
    .content-wrapper{
        max-width: 1100px;
        margin: auto;
    }
    .title{
        color: $theme-blue;
        margin-bottom: 40px;
    }
    p{
        padding: 1rem 0;
        font-size: 1rem;
    }
    .article-banner{
        width: 100%;
    }
    .image-container{
        margin: 0 auto 20px;
        max-width: 700px;
        width: 100%;
        box-shadow: 2px 2px 5px 0 rgba(128, 128, 128, 0.5);
        text-align: center;
        padding: 20px;
        border-radius: 10px;
        p{
            display: inline-block;
            font-size: 16px;
            margin-top: 5px;
            color: gray;
        }
        img{
            width: 100%;
        }
    }
    h1:not(.title){
        margin-top: 20px;
    }
    a{
        color: $theme-blue;
        text-decoration: underline;
    }
}

section.space{
    padding: 0 !important;
    height: 140px;
}

@media screen and (max-width: 1024px) {
    .banner.inner-page .content-wrapper{
        .left{
            padding: 4rem !important;
            .brand{
                padding-top: 0 !important;
            }
            .tile{
                height: 100% !important;
                img{
                    width: unset !important;
                }
            }
        }
    }
    .header-alt{
        .logo{
            filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.5));
        }
    }
}