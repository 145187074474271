@import "colors";

.contact-page{
    display: grid;
    grid-template-rows: auto auto;
    //grid-template-columns: 50% 50%;
    grid-template-columns: 100%;
    grid-auto-flow: column;
    padding-top: 10rem;
    .section{
        position: relative;
        z-index: 10;
        &-form{
            .title{
                color: white;
                padding-left: 2rem;
                padding-bottom: 2rem;
                margin: 0;
                position: relative;
            }
        }        
        &-contact-info{
            .contact-wrapper{
                background: white;
                color: black;
                // border-radius: 1.25rem;
                text-align: center;
                padding: 2rem 1rem;                
                h1.title-blue{
                    font-size: 1.25rem;
                    margin-bottom: 0;
                    font-weight: bold !important;
                }
                .title{
                    font-size: 2rem;
                    font-weight: normal !important;
                }
                .btn{
                    display: inline-block;
                    margin-top: 1rem;
                }
            }
        }
        &.body{
            padding: 0 2rem 5rem;
        }
    }
    .tile{
        width: 230px;
        position: absolute;
        top: -118px;
        left: -226px;
        z-index: -1;
        &-second{
            left: 0;
            top: unset;
            bottom: -4rem;
        }
    }
    form{
        font-size: 1.4rem !important;
        .submit-form{
            float: right;
            border: none;
            font-weight: bold;
        }
    }
}

.react-datepicker__time-container {
    width: 150px !important;
}
.react-datepicker__time-list,
.react-datepicker__time-box{
    width: 100% !important;
    padding: 0 !important;
    padding-right: 0 !important;
}
.react-datepicker-time__header{
    width: calc(100% - 15px);
}

.referral-page{
    background: $theme-blue-darker !important;
    &.leadsgen-1{
        .content-wrapper{
            grid-template-columns: 90% !important;
            .form-header{
                grid-template-columns: 17% 1fr;
                img{
                    padding-right: 30px;
                }
                h2{
                    font-weight: normal;
                    font-size: 20px;
                    margin-top: 10px !important;
                }
            }
        }   
    }
    .midbanner-img{
        position: relative;
        .partnerProgramTile{
            position: absolute;
            bottom: 3px;
            right: 0;
            width: 50% !important;
            height: auto !important;
        }
    }
    .midbanner-img-text{
        background: white;
        color: $theme-dark;
        padding: 5vw;
        height: 100%;
        h1{
            font-size: 3vw !important;
            color: $theme-blue;
        }
        ul{
            text-align: left;
            margin-top: 2rem;
            padding-left: 40px;
            li{
                padding: 1.2vw 0;
                display: flex;
                font-size: 1.25vw !important;
                display: flex;
                align-items: center;
                &.check-title{
                    color: $theme-blue;
                    font-size: 1.25rem;
                    font-weight: bold;
                }
                .check-title-inner{
                    color: $theme-blue;
                    font-weight: bold;
                    text-transform: uppercase;
                }
                img{
                    height: 2.5vw !important;
                    margin-right: 10px;
                    width: auto !important;
                }
            }
        }
        .note{
            font-size: 1vw;
        }
    }
    &.split{
        .right{
            background: white;
            color: $theme-dark;
            .midbanner-img{
                position: relative;
                .partnerProgramTile{
                    position: absolute;
                    bottom: 3px;
                    right: 0;
                    width: 50%;
                    height: auto;
                }
            }
            .top{
                img{
                    width: 100%;
                    height: 388px;
                }
            }
            .bottom{
                padding: 3.5rem;
                h1{
                    font-size: 3vw;
                    color: $theme-blue;
                }
                ul{
                    text-align: left;
                    margin-top: 2rem;
                    padding-left: 40px;
                    li{
                        padding: 10px 0;
                        display: flex;
                        font-size: 1.25vw;
                        &.check-title{
                            color: $theme-blue;
                            font-size: 1.25rem;
                            font-weight: bold;
                        }
                        .check-title-inner{
                            color: $theme-blue;
                            font-weight: bold;
                            text-transform: uppercase;
                        }
                    }
                    img{
                        height: 30px;
                        margin-right: 10px;
                        width: auto !important;
                    }
                }
                .note{
                    font-size: 1vw;
                }
            }
        }
    }
    .check-box{
        display: block;
    }
    .check-container{
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        padding-left: 35px;
        // height: 25px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 16px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        a{
            text-decoration: underline;
        }
        &:hover .checkmark {
            background-color: #ccc;
        }
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;
            &.error{
                &+.checkmark{
                    border-color: red;
                    &+span{
                        color: red;
                    }
                }
            }
            &:checked + .checkmark {
                background-color: #008cff;
                &:after {
                    display: block;
                }
            }
        }
        .checkmark {
            position: relative;
            // top: 0;
            // left: 0;
            display: block;
            height: 25px;
            width: 25px;
            margin-right: 10px;
            background-color: #eee;
            border: 1px solid transparent;
            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 9px;
                top: 5px;
                width: 5px;
                height: 10px;
                border: solid white;
                border-width: 0 3px 3px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            &+span{
                text-align: left;
            }
        }
    }
    .content-wrapper{
        align-items: start !important;
    }
    .left{
        position: relative;
        padding-top: 100px;
    }
    .right{
        height: 100%;
    }
    .form-header{
        display: grid;
        grid-template-columns: 30% 1fr;
        align-items: end;
        .tile{
            padding-right: 50px;
            margin-bottom: 50px;
        }
    }
    form{
        padding: 50px 0 50px 20%;
        text-align: center;
        .signup-link{
            color: #eee;
            text-decoration: underline;
            font-size: 16px;
            display: block;
            margin-top: 30px;
        }
        .bottom{
            display: flex;
            justify-content: space-between;
            align-items: center;
            input[type="submit"].btn{
                margin: 0 !important;
                margin-right: 20px !important;
            }
            .check-box{
                .check-container{
                    margin: 0 !important;
                    padding-left: 20px !important;
                }
            }
        }
        input,
        select,
        textarea{
            margin-bottom: 30px !important;
            padding: 15px 20px !important;
            border: 2px solid $theme-blue-darker !important;
            &.error{
                border: 2px solid red !important;
                color: red;
                &:focus-visible{
                    outline: red auto 4px !important;
                }
                &::placeholder{
                    color: red;
                }
            }
            &::placeholder{
                color: rgb(66, 65, 65);
                font-style: italic;
            }
            &.btn[type='submit']{
                margin-top: 10px !important;
                width: 200px;
            }
        }
    }
}