@import "colors";

html {
    scroll-behavior: smooth;
}

.products{
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
}

.prod-serv-mid-banner{
    background-color: #fff !important;
    position: relative;

    .content-wrapper{
        position: relative;
        &:not(:last-child){
            margin-bottom: 200px;
        }
        &.right-desc{
            height: 100%;
            width: 100%;
            grid-template-columns: 1fr 45% !important;
            .right{
                padding-left: 3rem !important;
            }
            .left{
                height: 100%;
                padding: 0;
                div{
                    height: 100%;
                    img{
                        height: 100%;
                        width: 100%;
                        // max-height: 500px;
                        object-fit: cover;
                    }
                }
            }
        }
        &.left-desc{
            height: 100%;
            width: 100%;
            .right{
                height: 100%;
                div{
                    height: 100%;
                    img{
                        height: 100%;
                        width: 100%;
                        // max-height: 500px;
                        object-fit: cover;
                    }
                }
            }
        }
        .left{
            div{
                height: 100%;
                width: 100%;
            }
            img{
                height: 100%;
                width: 100%;
                object-fit: cover;
                margin: 0 !important;
            }
        }
        h1{
            // text-wrap: nowrap;
            margin-bottom: 10px;
        }
    }
    h1{
        color: #ff9114 !important;
        font-weight: 400;
    }
    p{
        color: #000 !important;
    
    }

    .tile{
        position: absolute;
        img{
            width: 250px !important;
        }
    }

    .tile-1{
        top: -100px;
        left: -170px;
    }

    .tile-2{
        top: -130px;
        left: 47%;
        transform: translateX(-50%);
        img{
            width: 150px !important;
        }
    }

    .tile-3{
        top: -110px;
        left: -90px;
        img{
            width: 150px !important;
        }
    }

    .tile-4{
        top: -150px;
        right: 0;
    }

    .tile-5{
        top: -120px;
        left: -100px;
        img{
            width: 200px !important;
        }
    }

    .content-wrapper{
        .right{
            p{
                margin-bottom: 20px;
            }
        }
    }


}
.prod-serv-banner,
.prod-serv-mid-banner,
.products{
    p{
        font-size: 20px !important;
    }
}

@media screen and (max-width: 900px) {
    .midbanner{
        .content-wrapper{
            &.right-desc,
            &.left-desc{
                grid-template-columns: 1fr !important;

            }
            &.left-desc{
                .right{
                    order: -1;
                    padding-bottom: 30px !important;
                }
            }
            &.right-desc{
                .left{
                    padding-bottom: 30px !important;
                }
                .right{
                    padding: 0 !important;
                }
            }
            .left,
            .right{
                &>div{
                    margin-bottom: 20px !important;
                }
            }
        }
    }
}


@media screen and (max-width: 1024px) {
    .midbanner.prod-serv-mid-banner{
        .tile{
            &.tile-1{
                top: -150px !important;
                left: 240px !important;
            }
            &.tile-2{
                top: -125px !important;
            }
            &.tile-3{
                top: -190px !important;
                left: 0 !important;
            }
            &.tile-5{
                left: unset !important;
                right: 0 !important;
            }
        }
    }
    .prod-serv-banner,
    .prod-serv-mid-banner,
    .products{
        p{
            font-size: 30px !important;
        }
    }
    .intro{
        font-size: 30px !important;
        margin-bottom: 100px !important;
    }
}