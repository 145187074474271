@import "colors";


.product{
    &-main{
        ul{
            list-style: none !important;
        }
        .title{
            font-size: 1.75rem;
            color:  $theme-blue;
            margin-bottom: 10px;
        }
        .product-tag{
            color: $theme-blue;   
            border: 1px solid $theme-blue;
            border-radius: 5px;
            background: white;
            padding: 5px 15px;
        }
        .product-slogan{
            background: white;
            padding-bottom: 40px !important;
            .content-wrapper{
                display: grid;
                grid-template-columns: 1fr 1fr;
                &>div{
                    padding: 0 40px;
                }
                .left{
                    h1{
                        font-size: 1.75rem;
                        color:  $theme-blue;
                        margin-bottom: 10px;
                    }
                    p{
                        font-size: 1.1rem;
                        margin-bottom: 20px;
                    }
                    img{
                        width: 100%;
                    }
                }
                .right{
                    ul{
                        text-align: left;
                        margin-top: 5rem;
                        padding-left: 0px;
                        li{
                            padding: 10px 0;
                            display: flex;
                            &.check-title{
                                color: $theme-blue;
                                font-size: 1.25rem;
                                font-weight: bold;
                            }
                            .check-title-inner{
                                color: $theme-blue;
                                font-weight: bold;
                                text-transform: uppercase;
                            }
                        }
                        img{
                            height: 30px;
                            margin-right: 10px;
                        }
                    }
                    .product-features{
                        width: 100% !important;
                        .content-wrapper{
                            display: block !important;
                        }
                        .prices{
                            margin-top: 0 !important;
                        }
                    }
                }
            }
        }
        .product-features{
            background: white;
            padding-top: 40px !important;
            padding-bottom: 40px !important;
            text-align: center;
            ul.features{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                li{
                    margin: 5px;
                    font-size: 1.25rem;
                    border-width: 2px;
                }
            }
            .prices{
                background: $theme-blue;
                margin: 2em auto;
                color: white;
                padding: 40px !important;
                max-width: 600px;
                p{
                    font-size: 1.1em !important;
                }
            }
        }
        .product-video{
            background: white;
            padding-top: 40px !important;
            .video-wrapper{
                position: relative;
                width: 100%;
                padding-bottom: 56.25%;
                height: 0;
                .video{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    &-managedwifi{
        .empower{
            background: white;
            .content-wrapper{
                display: grid;
                grid-template-columns: 1fr 1fr;
                .left{
                    padding: 0;
                    img{
                        width: 100%;
                    }
                }
                .right{
                    text-align: left;
                    padding: 0 20px 0 30px;
                    h1{
                        font-size: 1.75rem;
                        color:  $theme-blue;
                        margin-bottom: 10px;
                    }
                    img{
                        height: 30px;
                    }
                    p{
                        font-size: 1.1rem;
                        margin-bottom: 20px;
                    }
                    ul{
                        text-align: left;
                        margin-top: 10px;
                        padding: 0;
                        li{
                            padding: 10px 0;
                            display: flex;
                        }
                        img{
                            height: 30px;
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
        .perks{
            padding: 0;
            .ctc-tile{
                position: absolute;
                top: 130px;
                left: -10px;
            }
            .content-wrapper{
                display: grid;
                grid-template-columns: 1fr 40%;
                color: white;
                align-items: center;
            }
            .left{
                padding-left: 12%;
                padding-right: 4rem;
                &>p{
                    font-size: 1.2rem !important;
                    font-weight: normal;
                }
            }
            .right{
                align-items: end;
                padding: 2rem 0;
                img{
                    max-height: 600px;
                    max-width: 600px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    object-position: center;
                    float: right;
                }
            }
            ul {
                padding: 0;
                li{
                    display: grid;
                    grid-template-columns: 50px 1fr;
                    padding-bottom: 40px;
                    font-size: 1rem !important;
                    img{
                        margin-right: 10px;
                    }
                }
            }
        }
    }
    &-itranspo{
        p{
            font-size: 1.1rem !important;
        }
        .solution{
            background: white;
            h1{
                color: $theme-blue;
                margin: 0 0 30px;
            }
            .content-wrapper{
                display: grid;
                grid-template-columns: 1fr 1fr;
                ul{
                    text-align: left;
                    margin-top: 10px;
                    padding: 0;
                    height: 130px;
                    li{
                        padding: 10px 0;
                        display: flex;
                    }
                    img{
                        height: 30px;
                        margin-right: 10px;
                    }
                }
                &>div{
                    padding: 0 40px;
                    &>img{
                        width: 100%;
                    }
                }
                .img-title{
                    height: 35px;
                    width: auto ;
                    margin: 2rem 0 1rem;
                }
            }
        }
        .howitworks{
            color: white;
            h1{
                font-size: 2rem;
                margin-bottom: 4rem;
            }
            .ctc-tile{
                position: absolute;
                top: 100px;
                left: 0;
            }
            .content-wrapper{
                article{
                    margin-bottom: 2rem;
                    .title{
                        border: 1px solid white;
                        font-size: 1.5rem;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 5px;
                    }
                    .process-container{
                        display: flex;
                        align-items: end;
                        .process-item{
                            text-align: center;
                            img{
                                max-height: 150px;
                                max-width: 150px;
                                margin: auto;
                                object-fit: contain;
                                object-position: center;
                            }
                            p{
                                margin-top: 20px;
                                height: 100px;
                            }
                        }
                        .arrow{
                            img{
                                height: 50px;
                            }
                            margin: 0 5px;
                            align-self: center;
                        }
                    }
                    .diagram{
                        display: grid;
                        grid-template-columns: 50% 50%;
                        margin-top: 20px;
                        &>div{
                            padding: 0 30px;
                        }
                        img{
                            width: 100%;
                            height: 100%;
                            object-fit: contain;
                            object-position: center;
                        }
                    }
                }
            }
        }
    }
}

main+section.contact-us:not(.hidden),
.contact-us{
    display: block !important;
}

@media screen and (max-width: 1024px) {
    .logo-independent{
        filter: drop-shadow(0 0 20px rgba(0, 0, 0, 0.5));
    }
    .product-main{
        .product-slogan .content-wrapper{
            grid-template-columns: 1fr !important;
            .left img{
                margin-bottom: 2rem;
            }
        }
        .product-features{
            li{
                font-size: 1.2em !important;
            }
        }

        .product-video{
            padding: 40px 0 0 0 !important;
        }
        .convenience{
            padding-top: 3rem !important;
            text-align: center;
            .content-wrapper{
                .device-container{
                    grid-template-columns: 1fr !important;
                }
                .device-item{
                    // display: flex;
                    grid-template-columns: 1fr 50px 1fr;
                    grid-template-rows: unset !important;
                    margin: 4rem 0 0;
                    align-items: center;
                    .hdr{
                        grid-column: 1/span 3;
                        margin-bottom: 20px;
                        // h3{
                        //     float: left !important;
                        //     margin-left: 4rem !important;
                        //     width: 250px;
                        // }
                    }
                    .arrow{
                        transform: rotate(-90deg);
                    }
                    img{
                        max-width: 100%;
                    }
                }
            }
        }
    }
    .check-title{
        font-size: 1.5rem !important;
    }
    p,
    ul li{
        font-size: 1.25rem !important;
    }
    .product-managedwifi{
        .empower,
        .perks{
            .content-wrapper{
                grid-template-columns: 1fr;
            }
        }
        .empower{
            .left img{
                margin-bottom: 20px !important;
            }
        }
        .perks{
            .ctc-tile{
                top: 100px !important;
                left: -40px !important;
            }
            .right img{
                float: unset !important;
                max-width: unset !important;
            }
            padding-bottom: 2rem !important;
        }
    }
    .product-itranspo{
        .solution .content-wrapper{
            grid-template-columns: 1fr !important;
            .left{
                margin-bottom: 20px;
            }
        }
        .howitworks{
            .ctc-tile{
                top: 0;
            }
        }
    }
}